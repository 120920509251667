// components/Sidebar.js
import React, { useState } from 'react'
// import Papa from 'papaparse'
// import * as XLSX from 'xlsx'
import { ToastContainer, toast } from 'react-toastify'
import apiClient from '../api/apiClient'

const names = ['temo', 'david', 'kere', 'zviad', 'rati', 'iva']

const Sidebar = ({ onSelectFilter, setJsonData }) => {
  const submitSuccess = () => toast.success('Added successfully')

  const filters = ['Shopify', 'Onway', 'Brunva', 'Debits']
  const [reason, setReason] = useState('')
  const [price, setPrice] = useState('')
  const [name, setName] = useState('')
  const [image, setImage] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState('')
  const handleImageChange = (e) => {
    console.log(e.target.files[0])
    setImage(e.target.files[0])
  }

  //UPLOAD EXCEL FILE
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0]
  //   if (file) {
  //     const fileExtension = file.name.split('.').pop().toLowerCase()

  //     if (fileExtension === 'csv') {
  //       parseCSV(file)
  //     } else if (fileExtension === 'xlsx') {
  //       parseXLSX(file)
  //     } else {
  //       alert('Please upload a CSV or XLSX file')
  //     }
  //   }
  // }

  // const parseCSV = (file) => {
  //   Papa.parse(file, {
  //     header: true,
  //     skipEmptyLines: true,
  //     complete: (result) => {
  //       setJsonData(result.data)
  //       console.log(result.data)
  //     },
  //     error: (error) => {
  //       console.error('Error parsing CSV file:', error.message)
  //     },
  //   })
  // }

  // const parseXLSX = (file) => {
  //   const reader = new FileReader()
  //   reader.onload = (e) => {
  //     const data = new Uint8Array(e.target.result)
  //     const workbook = XLSX.read(data, { type: 'array' })
  //     const sheetName = workbook.SheetNames[0]
  //     const worksheet = workbook.Sheets[sheetName]
  //     const json = XLSX.utils.sheet_to_json(worksheet)
  //     console.log(json)
  //     setJsonData(json)
  //   }
  //   reader.onerror = (error) => {
  //     console.error('Error reading XLSX file:', error.message)
  //   }
  //   reader.readAsArrayBuffer(file)
  // }

  const submitHandler = () => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('price', price)
    formData.append('reason', reason)
    formData.append('type', paymentMethod)
    formData.append('image', image)
    console.log(image, formData.get('image'))

    apiClient
      .post('/api/purchase', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        submitSuccess()
      })
  }

  return (
    <>
      <div className='sidebar'>
        <div>
          {filters.map((filter) => (
            <button
              key={filter}
              onClick={() => onSelectFilter(filter)}
              style={{ marginBottom: 10 }}
            >
              {filter}
            </button>
          ))}
        </div>
        {/* Inputs for Reason, Price, and Quantity */}
        <div>
          <div className='input-section'>
            <label>
              Reason:
              <input
                type='text'
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder='Input reason'
              />
            </label>

            <label>
              Price:
              <input
                type='number'
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder='Input price'
              />
            </label>

            <label>
              Name:
              <br></br>
              <select
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ padding: 10, width: '100%' }}
              >
                <option value='' disabled>
                  Select Name
                </option>
                {names.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className='checkmarks'>
            <label>
              <input
                type='radio'
                name='paymentMethod'
                value='cash'
                checked={paymentMethod === 'cash'}
                onChange={() => setPaymentMethod('cash')}
              />
              Cash
            </label>
            <label>
              <input
                type='radio'
                name='paymentMethod'
                value='creditCard'
                checked={paymentMethod === 'creditCard'}
                onChange={() => setPaymentMethod('creditCard')}
              />
              Credit Card
            </label>
          </div>
        </div>
        {/* Attach File Button */}
        <div className='attach-file'>
          <label>
            <input
              type='file'
              accept='image/*'
              onChange={handleImageChange}
              required
              style={{ display: 'none' }}
            />
            <button
              type='button'
              onClick={() =>
                document.querySelector('input[type="file"]').click()
              }
            >
              Attach File
            </button>
          </label>
          <button onClick={submitHandler}>Submit</button>
        </div>
      </div>
      <ToastContainer
        position='top-center'
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </>
  )
}

export default Sidebar
