// src/index.js

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css' // Import global styles
import App from './App'
import 'react-toastify/dist/ReactToastify.css'

// Render the App component inside the root div in public/index.html
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
