import React, { useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import Sidebar from './components/Sidebar'
import Content from './components/Content'
import Login from './components/Login'
import './App.css'

function App() {
  const [selectedFilter, setSelectedFilters] = useState('')
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('token')
  )

  const handleLoginSuccess = () => {
    setIsAuthenticated(true)
  }

  return (
    <Router>
      <Routes>
        <Route
          path='/login'
          element={
            isAuthenticated ? (
              <Navigate to='/' />
            ) : (
              <Login onLoginSuccess={handleLoginSuccess} />
            )
          }
        />
        <Route
          path='/'
          element={
            isAuthenticated ? (
              <div className='app-container'>
                <Sidebar onSelectFilter={setSelectedFilters} />
                <Content selectedFilter={selectedFilter} />
              </div>
            ) : (
              <Navigate to='/login' />
            )
          }
        />
        <Route path='*' element={<Navigate to='/login' />} />
      </Routes>
    </Router>
  )
}

export default App
