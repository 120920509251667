// components/Content.js
import React, { useEffect, useState } from 'react'
import apiClient from '../api/apiClient'

const Content = ({ selectedFilter, jsonData }) => {
  // Shopify filter options
  const shopifyFilters = [
    { label: 'Total of Paid Orders', value: 'totalPaidOrders' },
    {
      label: 'Total of Paid Cash on Delivery Orders',
      value: 'totalPaidCashOnDelivery',
    },
    { label: 'Unpaid Cash on Delivery Orders', value: 'unpaidCashOnDelivery' },
    {
      label: 'Total of Paid Bank Deposit Orders',
      value: 'totalPaidBankDeposit',
    },
    { label: 'Unpaid Bank Deposit Orders', value: 'unpaidBankDeposit' },

    { label: 'Pending Orders', value: 'totalPendingOrders' },

    { label: 'Refunded Orders', value: 'totalRefundedOrders' },

    { label: 'Paid Tbilisi Shipping', value: 'countTbilisiShipping' },

    { label: 'Paid Region Shipping', value: 'countRegionShipping' },

    { label: 'Paid No Shipping', value: 'countNoShipping' },
  ]

  // Onway filter options
  const onwayFilters = {
    trackingOptions: [
      'Tracking Option 1',
      'Tracking Option 2',
      'Tracking Option 3',
    ],
    statusOptions: [
      'არ ჩაბარდა',
      'არ ჩაბარდა/დასრულებული',
      'აღების გაუქმება',
      'გაგზავნილი ფილიალში',
      'გატანილი ჩასაბარებლად',
      'მიღების გაუქმება',
      'საწყობში',
      'უბრუნდება გამგზავნს',
      'ფილიალიდან გაცემა',
      'შეკვეთის გაუქმება',
      'ჩაბარებული',
      'ხელმეორედ გატანა',
    ],
    dateOptions: ['შეკვ. თარიღი', 'აღების თარიღი', 'დას. თარიღი'],
    courierOptions: [
      '6 GEL - თბილისი',
      '10 GEL - რეგიონები',
      '15 GEL - სოფლები',
      '0 GEL - ტაქსით-ჩვენთან მოვიდა',
    ],
    additionalFilters: [
      { label: 'Product Price', value: 'productPrice' },
      { label: 'Commission', value: 'commission' },
      { label: 'Brunva', value: 'brunva' },
    ],
  }

  // Brunva filter options
  const incomeOptions = [
    { label: 'Shopify total', value: 'shopifyTotal' },
    { label: 'COD count + value', value: 'codCountValue' },
    { label: 'Bank deposit count + value', value: 'bankDepositCountValue' },
  ]

  const feesOptions = [
    { label: 'Courier value', value: 'courierValue' },
    { label: 'Sakomisio onway', value: 'sakomisioOnway' },
    { label: 'Ads', value: 'ads' },
    { label: 'Komunalurebi', value: 'komunalurebi' },
    { label: 'Rent', value: 'rent' },
    { label: 'Xelpasebi', value: 'xelpasebi' },
    { label: 'Shopify', value: 'shopify' },
    { label: 'Gmail', value: 'gmail' },
    { label: 'ChatGPT', value: 'chatgpt' },
  ]

  // Debits filter options
  const companyOptions = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
  ]

  const individualOptions = [
    { label: 'Individual 1', value: 'individual1' },
    { label: 'Individual 2', value: 'individual2' },
    { label: 'Individual 3', value: 'individual3' },
  ]

  const [selectedFilters, setSelectedFilters] = useState([])
  const [tracking, setTracking] = useState('')
  const [status, setStatus] = useState('')
  const [dates, setDates] = useState('')
  const [courier, setCourier] = useState('')
  const [incomeSelections, setIncomeSelections] = useState([])
  const [feesSelections, setFeesSelections] = useState([])
  const [isIncomeOpen, setIncomeOpen] = useState(false)
  const [isFeesOpen, setFeesOpen] = useState(false)
  const [purchasesOpen, setPurchasesOpen] = useState(false)
  const [companySelections, setCompanySelections] = useState([])
  const [purchaseOptions, setPurchaseOptions] = useState([])
  const [purchaseSelections, setPurchaseSelections] = useState([])
  const [purchaseStats, setPurchaseStats] = useState([])
  const [individualSelections, setIndividualSelections] = useState([])
  const [isCompanyOpen, setCompanyOpen] = useState(false)
  const [isIndividualOpen, setIndividualOpen] = useState(false)
  const [statistics, setStatistics] = useState({})

  useEffect(() => {
    apiClient.get('/api/purchase-options').then((res) => {
      const reasons = res.data.map((pur) => {
        console.log(pur)

        return {
          label: pur.reason,
          value: pur.reason,
          id: pur._id,
        }
      })

      setPurchaseOptions(reasons)
    })
  }, [])
  console.log(purchaseStats)

  const filterHandler = () => {
    const dataArray = jsonData
    const results = {}

    selectedFilters.forEach((filter) => {
      switch (filter) {
        case 'totalPaidOrders': {
          const filteredData = dataArray.filter(
            (item) => item['Financial Status'] === 'paid'
          )
          console.log(filteredData)

          const totalAmount = filteredData.reduce(
            (acc, item) => acc + (Number(item.Total) || 0),
            0
          )
          const count = filteredData.length

          results[filter] = { total: totalAmount, count: count }
          break
        }

        case 'totalPaidBankDeposit': {
          const filteredData = dataArray.filter(
            (item) =>
              item['Financial Status'] === 'paid' &&
              item['Payment Method'] === 'Bank Deposit'
          )

          const totalAmount = filteredData.reduce(
            (acc, item) => acc + (Number(item.Total) || 0),
            0
          )
          const count = filteredData.length

          results[filter] = { total: totalAmount, count: count }
          break
        }

        case 'totalPaidCashOnDelivery': {
          const filteredData = dataArray.filter(
            (item) =>
              item['Financial Status'] === 'paid' &&
              item['Payment Method'] === 'Cash on Delivery (COD)'
          )

          const totalAmount = filteredData.reduce(
            (acc, item) => acc + (Number(item.Total) || 0),
            0
          )
          const count = filteredData.length

          results[filter] = { total: totalAmount, count: count }
          break
        }

        default:
          console.warn(`Filter ${filter} is not recognized.`)
          break
      }
    })

    return results
  }

  const handleSelectionChange = (option, setSelections) => {
    setSelections((prevSelections) =>
      prevSelections.includes(option)
        ? prevSelections.filter((item) => item !== option)
        : [...prevSelections, option]
    )
  }

  return (
    <div className='content'>
      {/* Shopify Filters */}
      {selectedFilter === 'Shopify' && (
        <div>
          <h2>Shopify Filters</h2>
          <div className='filter-options shopify-filters'>
            {shopifyFilters.map((filter) => (
              <div className='filter-item'>
                <label key={filter.value}>
                  <input
                    type='checkbox'
                    checked={selectedFilters.includes(filter.value)}
                    onChange={() =>
                      handleSelectionChange(filter.value, setSelectedFilters)
                    }
                  />
                  {filter.label}
                </label>
                <h3>
                  {statistics[filter.value]
                    ? `Total: ${statistics[filter.value].total}, Count: ${
                        statistics[filter.value].count
                      }`
                    : 'No data'}
                </h3>
              </div>
            ))}
          </div>
          <button
            className='filter-button'
            onClick={() => {
              const results = filterHandler()
              setStatistics(results)
              console.log('Applying Shopify Filters:', selectedFilters)
            }}
          >
            Filter
          </button>
        </div>
      )}

      {/* Onway Filters */}
      {selectedFilter === 'Onway' && (
        <div>
          <h2>Onway Filters</h2>
          <ul className='onway-filters'>
            <li>
              <label>Tracking:</label>
              <select
                value={tracking}
                onChange={(e) => setTracking(e.target.value)}
              >
                <option value=''>Select Tracking</option>
                {onwayFilters.trackingOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </li>
            <li>
              <label>Status:</label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value=''>Select Status</option>
                {onwayFilters.statusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </li>
            <li>
              <label>Dates:</label>
              <select value={dates} onChange={(e) => setDates(e.target.value)}>
                <option value=''>Select Date</option>
                {onwayFilters.dateOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </li>
            <li>
              <label>Courier:</label>
              <select
                value={courier}
                onChange={(e) => setCourier(e.target.value)}
              >
                <option value=''>Select Courier</option>
                {onwayFilters.courierOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </li>
            {onwayFilters.additionalFilters.map((filter) => (
              <li key={filter.value} className='filter-item'>
                <input
                  type='checkbox'
                  checked={selectedFilters.includes(filter.value)}
                  onChange={() =>
                    handleSelectionChange(filter.value, setSelectedFilters)
                  }
                />
                {filter.label}
              </li>
            ))}
          </ul>
          <button
            className='filter-button'
            onClick={() =>
              console.log('Applying Onway Filters:', {
                tracking,
                status,
                dates,
                courier,
                selectedFilters,
              })
            }
          >
            Filter
          </button>
        </div>
      )}

      {/* Brunva Filters */}
      {selectedFilter === 'Brunva' && (
        <div>
          <h2>Brunva Filters</h2>
          <ul className='brunva-filters'>
            <li>
              <label>Income:</label>
              <div className='dropdown' style={{ position: 'relative' }}>
                <button
                  className='dropdown-toggle'
                  onClick={() => setIncomeOpen(!isIncomeOpen)}
                >
                  Select Income
                </button>
                <div
                  className={`dropdown-menu ${isIncomeOpen ? 'active' : ''}`}
                >
                  {incomeOptions.map((option) => (
                    <label key={option.value} className='filter-item'>
                      <input
                        type='checkbox'
                        checked={incomeSelections.includes(option.value)}
                        onChange={() =>
                          handleSelectionChange(
                            option.value,
                            setIncomeSelections
                          )
                        }
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              </div>
            </li>
            <li>
              <label>Fees:</label>
              <div className='dropdown' style={{ position: 'relative' }}>
                <button
                  className='dropdown-toggle'
                  onClick={() => setFeesOpen(!isFeesOpen)}
                >
                  Select Fees
                </button>
                <div className={`dropdown-menu ${isFeesOpen ? 'active' : ''}`}>
                  {feesOptions.map((option) => (
                    <label key={option.value} className='filter-item'>
                      <input
                        type='checkbox'
                        checked={feesSelections.includes(option.value)}
                        onChange={() =>
                          handleSelectionChange(option.value, setFeesSelections)
                        }
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              </div>
            </li>
            <li>
              <label>Purchases:</label>
              <div className='dropdown' style={{ position: 'relative' }}>
                <button
                  className='dropdown-toggle'
                  onClick={() => setPurchasesOpen((prev) => !prev)}
                >
                  Select Purchases
                </button>
                <div
                  className={`dropdown-menu ${purchasesOpen ? 'active' : ''}`}
                >
                  {purchaseOptions?.map((option) => (
                    <label key={option.value} className='filter-item'>
                      <input
                        type='checkbox'
                        checked={purchaseSelections.includes(option.value)}
                        onChange={() =>
                          handleSelectionChange(
                            option.value,
                            setPurchaseSelections
                          )
                        }
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              </div>
            </li>
          </ul>
          <button
            className='filter-button'
            onClick={() => {
              console.log('Applying Brunva Filters:', {
                purchaseSelections,
              })
              apiClient
                .get('/api/purchases/by-reasons', {
                  params: {
                    reasons: purchaseSelections,
                  },
                })
                .then((res) => setPurchaseStats(res.data))
            }}
          >
            Filter
          </button>
          <ul style={{ marginTop: 20 }}>
            {purchaseStats.map((purch) => (
              <li style={{ marginTop: 10 }}>
                <b>Reason:</b> {purch.reason} <b>Price: </b>
                {purch.price}, <b>Payment:</b> {purch.type}, <b>Author: </b>
                {purch.name} <img src={purch.imageUrl} alt='' />
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Debits Filters */}
      {selectedFilter === 'Debits' && (
        <div>
          <h2>Debits Filters</h2>
          <ul className='debits-filters'>
            <li>
              <label>Company:</label>
              <div className='dropdown' style={{ position: 'relative' }}>
                <button
                  className='dropdown-toggle'
                  onClick={() => setCompanyOpen(!isCompanyOpen)}
                >
                  Select Company
                </button>
                <div
                  className={`dropdown-menu ${isCompanyOpen ? 'active' : ''}`}
                >
                  {companyOptions.map((option) => (
                    <label key={option.value} className='filter-item'>
                      <input
                        type='checkbox'
                        checked={companySelections.includes(option.value)}
                        onChange={() =>
                          handleSelectionChange(
                            option.value,
                            setCompanySelections
                          )
                        }
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              </div>
            </li>
            <li>
              <label>Individuals:</label>
              <div className='dropdown' style={{ position: 'relative' }}>
                <button
                  className='dropdown-toggle'
                  onClick={() => setIndividualOpen(!isIndividualOpen)}
                >
                  Select Individuals
                </button>
                <div
                  className={`dropdown-menu ${
                    isIndividualOpen ? 'active' : ''
                  }`}
                >
                  {individualOptions.map((option) => (
                    <label key={option.value} className='filter-item'>
                      <input
                        type='checkbox'
                        checked={individualSelections.includes(option.value)}
                        onChange={() =>
                          handleSelectionChange(
                            option.value,
                            setIndividualSelections
                          )
                        }
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              </div>
            </li>
          </ul>
          <button
            className='filter-button'
            onClick={() =>
              console.log('Applying Debits Filters:', {
                companySelections,
                individualSelections,
              })
            }
          >
            Filter
          </button>
        </div>
      )}

      {/* Fallback message if no filter is selected */}
      {!['Shopify', 'Onway', 'Brunva', 'Debits'].includes(selectedFilter) && (
        <p>Please select a filter from the left.</p>
      )}
    </div>
  )
}

export default Content
